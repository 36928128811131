<script>
import { mapGetters, mapState } from 'vuex';
import imgEcriture from '@app/assets/img/ai_assistant/ecriture.png';
import imgSuper from '@app/assets/img/ai_assistant/super.png';
import imgIdee from '@app/assets/img/ai_assistant/idee.png';
import AICreditsBox from '@app/components/AICreditsBox.vue';

// preload images
[imgEcriture, imgSuper, imgIdee].forEach((v) => (new Image()).src = v);

export default {
  components: {
    AICreditsBox,
  },
  data: () => ({
    isLoading: false,
    hasAICreditsBoxOpen: false,
  }),
  computed: {
    ...mapGetters('auth', {
      authUser: 'user',
      authStore: 'store',
      aiCreditsLeft: 'aiCreditsLeft',
    }),
    ...mapState('aiContents', [
      'contents',
    ]),
  },
  created() {
    if (this.$route.query.payment === 'cancel') {
      this.$buefy.dialog.confirm({
        title: 'Avez-vous rencontré un souci ?',
        message: `
          Il semblerait que vous ne soyez pas
          allé au bout de votre achat. Que s'est-il passé ?
        `,
        focusOn: 'cancel',
        onConfirm: () => {
          this.$buefy.loading.open();
          window.location.href = 'https://forms.gle/r4NDEphrspt8Jg8c7';
        },
        cancelText: 'Non merci, rien à dire',
        confirmText: 'Je vous dis tout',
      });
    }

    if (this.$route.query.payment === 'success') {
      this.$buefy.dialog.alert({
        title: 'Votre achat a été effectué avec succès !',
        message: `
          Si vos crédits n'ont pas été mis à jour,
          veuillez attendre 2 minutes et recharger la page.
        `,
      });
    }

    this.isLoading = true;
    this.$store.dispatch('aiContents/fetch', {
      storeUUID: this.authStore.uuid,
    })
      .then(() => (
        this.contents.data.length <= 0
        && this.$router.push({ name: 'ai_assistant_dashboard' })
      ))
      .finally(() => (this.isLoading = false));
  },
};
</script>

<template>
  <div class="container">
    <header class="columns is-vcentered">
      <div class="column is-8">
        <h1 class="title is-5">
          Bonjour {{ authUser.firstname }} !
        </h1>

        <p class="subtitle is-6">
          Je suis votre copywriter personnel, spécialisé en rédaction de page de vente pour les formations en ligne.
        </p>
      </div>
      <div class="column is-4">
        <div class="box has-background-facebook has-text-white">
          <p>
            <strong>Crédits restants : </strong> {{ aiCreditsLeft }}
          </p>
          <p class="mt-2">
            <b-button
              class="py-6"
              type="is-black"
              inverted
              expanded
              @click="hasAICreditsBoxOpen = true"
            >
              Acheter des crédits
            </b-button>
          </p>
        </div>
      </div>
    </header>

    <div class="tabs mb-10">
      <ul>
        <router-link
          tag="li"
          :class="{'is-active': $route.name == 'ai_assistant'}"
          :to="{name: 'ai_assistant', params: $route.params}"
        >
          <a>Utiliser mon Copywriter</a>
        </router-link>
        <router-link
          tag="li"
          :to="{name: 'ai_assistant_history', params: $route.params}"
          exact-active-class="is-active"
        >
          <a>Historique</a>
        </router-link>
        <router-link
          tag="li"
          :to="{name: 'ai_assistant_dashboard', params: $route.params}"
          exact-active-class="is-active"
        >
          <a>Onboarding &amp; Tutoriels</a>
        </router-link>
      </ul>
    </div>
    <div>
      <b-skeleton v-if="isLoading" height="400" />
      <router-view v-else />
    </div>

    <b-modal
      v-if="hasAICreditsBoxOpen"
      :can-cancel="false"
      fullScreen
      active
    >
      <AICreditsBox @close="hasAICreditsBoxOpen = false" />
    </b-modal>
  </div>
</template>
